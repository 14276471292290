<template>
    <HeaderNav />
    <main>
        <AboutSection />
    </main>
    <FooterNav />
</template>

<script>
import { defineComponent } from 'vue';
import HeaderNav from '@/components/layout/HeaderNav.vue';
import FooterNav from '@/components/layout/FooterNav.vue';
import AboutSection from '@/components/about/AboutSection.vue';

export default defineComponent({
    components: {
        HeaderNav,
        FooterNav,
        AboutSection,
    },
});
</script>
