<template>
    <div class="items-wrap">
        <div
            v-for="id in ids"
            :id="id"
            :key="id"
            class="items marquee"
            :class="{ reverce: reverse }"
            aria-hidden="true"
            @mouseover="stopScroll"
            @mouseleave="resumeScroll"
        >
            <p
                v-for="n in 5"
                :key="n"
                class="item brand_img"
            >
                Партнеры.
            </p>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'MarqueeRow',
    props: {
        ids: {
            type: Array,
            required: true,
        },
        reverse: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        stopScroll () {
            this.ids.forEach(id => {
                document.getElementById(id).style.animationPlayState = 'paused';
            });
        },
        resumeScroll () {
            this.ids.forEach(id => {
                document.getElementById(id).style.animationPlayState = 'running';
            });
        },
    },
});
</script>
