<template>
    <div class="box_vacancies_button">
        <div class="box_button_w243_h71">
            <button
                class="button_standart"
                @click="$emit('apply')"
            >
                откликнуться
            </button>
        </div>
        <p class="promotion_text text_black text_align_right">
            <template
                v-for="(word, index) in formattedTitle"
                :key="index"
            >
                {{ word }}
                <br v-if="index < formattedTitle.length - 1" />
            </template>
        </p>
    </div>
</template>

<script>
export default {
    name: 'VacancyButton',
    props: {
        title: {
            type: String,
            required: true,
        },
    },
    computed: {
        formattedTitle () {
            return this.title.split(' ');
        },
    },
};
</script>
