<template>
    <HeaderNav :title="pageTitle" />
    <VacancyPromotion />
    <section class="section_vacancies">
        <div
            v-for="vacancy in vacancies"
            :key="vacancy.title"
            class="container_vacancies"
        >
            <VacancyButton
                :title="vacancy.title"
                @apply="toggleModalVacancies(vacancy)"
            />
            <VacancyDetails
                :vacancy="vacancy"
                @apply="toggleModalVacancies(vacancy)"
            />
        </div>

        <VacancyModal
            v-if="showModalVacancies"
            :vacancy="selectedVacancy"
            @close="closeModalVacancies"
            @submit="submitForm"
        />
    </section>
    <FooterNav />
</template>

<script>
import { ref, onMounted } from 'vue';

import HeaderNav from '@/components/layout/HeaderNav.vue';
import FooterNav from '@/components/layout/FooterNav.vue';
import VacancyPromotion from '@/components/vacancies/VacancyPromotion.vue';
import VacancyButton from '@/components/vacancies/VacancyButton.vue';
import VacancyDetails from '@/components/vacancies/VacancyDetails.vue';
import VacancyModal from '@/components/vacancies/VacancyModal.vue';
import { API_ENDPOINTS } from '@/utils/constants';

export default {
    components: {
        HeaderNav,
        FooterNav,
        VacancyPromotion,
        VacancyButton,
        VacancyDetails,
        VacancyModal,
    },
    setup () {
        const pageTitle = ref('Вакансии');
        const showModalVacancies = ref(false);
        const selectedVacancy = ref(null);
        const vacancies = ref([]);
        const error = ref(null);
        // const vacancies = [
        //     { title: 'Менеджер отдела снабжения', salary: 'От 30 000 рублей' },
        //     { title: 'Вакансия-2', salary: 'От 30 000 рублей' },
        //     { title: 'Вакансия-3', salary: 'От 30 000 рублей' },
        //     { title: 'Вакансия-4', salary: 'От 30 000 рублей' },
        // ];

        onMounted(async () => {
            try {
                const response = await fetch(API_ENDPOINTS.VACANCIES);

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();

                vacancies.value = data.items;
                console.log(vacancies.value);
            } catch (e) {
                error.value = e.message;
                console.log(error.value);
            }
        });

        const toggleModalVacancies = (vacancy) => {
            if (showModalVacancies.value && selectedVacancy.value === vacancy) {
                closeModalVacancies();
            } else {
                selectedVacancy.value = vacancy;
                showModalVacancies.value = true;
                document.body.classList.add('no-scroll');
                document
                    .querySelector('.section_header')
                    .classList.add('z-index-0');
            }
        };

        const closeModalVacancies = () => {
            showModalVacancies.value = false;
            selectedVacancy.value = null;
            document.body.classList.remove('no-scroll');
            document
                .querySelector('.section_header')
                .classList.remove('z-index-0');
        };

        const submitForm = (formData) => {
            console.log('Form submitted:', formData);
            closeModalVacancies();
        };

        return {
            pageTitle,
            showModalVacancies,
            selectedVacancy,
            vacancies,
            toggleModalVacancies,
            closeModalVacancies,
            submitForm,
        };
    },
};
</script>
