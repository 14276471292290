<template>
    <div
        class="modal_vacancies"
        @click="closeModal"
    >
        <div
            class="modal_vacancie_contern"
            @click.stop
        >
            <p class="decor_modal_vacancie">ВАКАНСИИ</p>
            <p class="decor_modal_vacancie_v2 text_align_right">
                ВАКАН<br />СИИ
            </p>
            <p class="technologies_text text_align_left">
                Описания вакансии<br />
                (опыт работы, условия,<br />
                график работы)
            </p>
            <div class="box_content_modal_vacancie">
                <div class="box_input_modal_vacancie">
                    <p class="modal_vacancie_text">вакансия</p>
                    <input
                        class="input_modal_vacancie"
                        type="text"
                        :value="vacancy.title"
                        readonly
                    />
                </div>
                <div class="box_input_modal_vacancie">
                    <p
                        class="modal_vacancie_text"
                        :class="{ error: phoneError && touched.phone }"
                    >
                        номер телефона
                        <span
                            v-if="phoneError && touched.phone"
                            class="error"
                        >
                            * некорректный
                        </span>
                    </p>
                    <input
                        v-model="phoneNumber"
                        class="input_modal_vacancie"
                        type="tel"
                        placeholder="+7 999 000 00 02"
                        @input="formatPhoneNumber"
                        @blur="validatePhone"
                    />
                </div>
                <div class="box_input_modal_vacancie">
                    <p
                        class="modal_vacancie_text"
                        :class="{ error: nameError && touched.name }"
                    >
                        имя
                        <span
                            v-if="nameError && touched.name"
                            class="error"
                        >
                            * минимум 2 символа
                        </span>
                    </p>
                    <input
                        v-model="name"
                        class="input_modal_vacancie"
                        type="text"
                        @blur="validateName"
                    />
                </div>
                <div class="box_input_modal_vacancie">
                    <p
                        class="modal_vacancie_text"
                        :class="{ error: surnameError && touched.surname }"
                    >
                        фамилия
                        <span
                            v-if="surnameError && touched.surname"
                            class="error"
                        >
                            * минимум 2 символа
                        </span>
                    </p>
                    <input
                        v-model="surname"
                        class="input_modal_vacancie"
                        type="text"
                        @blur="validateSurname"
                    />
                </div>
            </div>
            <div class="box_button_modal_vacancie">
                <p class="subtext_services_light text_align_left">
                    Нажимая на кнопку, вы подтверждаете согласие<br />
                    на обработку персональных данных
                </p>
                <div class="test">
                    <div class="box_button_w331_h71">
                        <button
                            :class="{ submitted: isSubmitted }"
                            class="button_standart"
                            :disabled="isSubmitted"
                            @click="submitForm"
                        >
                            {{
                                isSubmitted
                                    ? 'Отклик отправлен'
                                    : 'Откликнуться'
                            }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue';

export default {
    name: 'VacancyModal',
    props: {
        vacancy: {
            type: Object,
            required: true,
        },
    },
    emits: ['close', 'submit'],
    setup (props, { emit }) {
        const phoneNumber = ref('');
        const name = ref('');
        const surname = ref('');
        const isSubmitted = ref(false);
        const submissions = ref([]);
        const submitTimeout = ref(null);

        const phoneError = ref(false);
        const nameError = ref(false);
        const surnameError = ref(false);

        const touched = ref({
            phone: false,
            name: false,
            surname: false,
        });

        const formatPhoneNumber = (event) => {
            let input = event.target.value.replace(/\D/g, '');

            if (input.startsWith('7')) {
                input = input.slice(1);
            }

            let formatted = '+7';

            if (input.length > 0) formatted += ' (' + input.substring(0, 3);
            if (input.length > 3) formatted += ') ' + input.substring(3, 6);
            if (input.length > 6) formatted += ' ' + input.substring(6, 8);
            if (input.length > 8) formatted += ' ' + input.substring(8, 10);

            phoneNumber.value = formatted;
        };

        const validatePhone = () => {
            phoneError.value = !/^\+7 \(\d{3}\) \d{3} \d{2} \d{2}$/.test(
                phoneNumber.value,
            );
            touched.value.phone = true;
        };

        const validateName = () => {
            nameError.value = name.value.length < 2;
            touched.value.name = true;
        };

        const validateSurname = () => {
            surnameError.value = surname.value.length < 2;
            touched.value.surname = true;
        };

        const submitForm = () => {
            validatePhone();
            validateName();
            validateSurname();

            if (!phoneError.value && !nameError.value && !surnameError.value) {
                isSubmitted.value = true;

                setTimeout(() => {

                    emit('submit', {
                        vacancy: props.vacancy.title,
                        phoneNumber: phoneNumber.value,
                        name: name.value,
                        surname: surname.value,
                    });
                    submitTimeout.value = null; // Отправляем данные
                }, 12000);
            }
        };

        const closeModal = () => {
            // Если таймер активен, не очищаем его, данные всё равно отправятся
            if (!submitTimeout.value) {
                emit('submit', {
                    vacancy: props.vacancy.title,
                    phoneNumber: phoneNumber.value,
                    name: name.value,
                    surname: surname.value,
                });
            } else {
                emit('close');
            }
        };

        return {
            phoneNumber,
            name,
            surname,
            phoneError,
            nameError,
            surnameError,
            touched,
            isSubmitted,
            formatPhoneNumber,
            validatePhone,
            validateName,
            validateSurname,
            submitForm,
            closeModal,
        };
    },
};
</script>

<style scoped>
.button_standart {
    transition: background-color 0.3s ease;
}

.button_standart.submitted {
    background-color: #adc130;
    color: white;
    cursor: not-allowed;
}
.technologies_text {
    display: none;
}
</style>
