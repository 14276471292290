import { createRouter, createWebHistory } from 'vue-router';
import { mainRoutes } from './routes/mainRoutes';
import { scheduleRoutes } from './routes/scheduleRoutes';
import { employeeRoutes } from './routes/employeeRoutes';
import employeeAccountRoutes from './routes/employeeAccountRoutes';
import adminRoutes from './routes/adminRoutes';
import { checkAdminAuth, checkEmployeeAuth } from './guards';

const routes = [
    ...mainRoutes,
    ...employeeRoutes,
    ...scheduleRoutes,
    ...employeeAccountRoutes,
    ...adminRoutes,
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach(checkAdminAuth);
router.beforeEach(checkEmployeeAuth);

export default router;
