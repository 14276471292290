<template>
    <section class="section_technologies">
        <!-- <img
            src="@/assets/background/Line_4.svg"
            class="line_4"
        /> <img
            src="@/assets/background/Line_4_mob.svg"
            class="line_4_mob"
        />-->
        <img
            src="@/assets/background/Triangles_4_mob_v2.svg"
            class="Triangles_4_mob_v2"
        />
        <h3 class="characteristics_title text_align_center margin_bottom_64px">
            Используемые технологии
        </h3>
        <Swiper
            :slides-per-view="slidesPerView"
            :space-between="spaceBetween"
            :loop="false"
            :autoplay="false"
            :breakpoints="slidesPerView"
            class="technologiesSwiper"
        >
            <SwiperSlide
                v-for="(tech, index) in technologies"
                :key="index"
                class="block_technologies"
            >
                <p
                    class="characteristics_title_meter text_white text_align_center"
                    :class="{ height_40px: tech.title.includes('<br>') }"
                >
                    <span
                        class="text_white characteristics_title_meter"
                        v-html="tech.title"
                    ></span>
                </p>
                <p
                    class="technologies_text text_white"
                    v-html="tech.description"
                ></p>
            </SwiperSlide>
        </Swiper>
        <div class="container_technologies">
            <div
                v-for="(tech, index) in technologies"
                :key="index"
                class="block_technologies"
            >
                <p
                    class="characteristics_title_meter text_white text_align_center"
                    :class="{ height_40px: tech.title.includes('<br>') }"
                >
                    <span
                        class="text_white characteristics_title_meter"
                        v-html="tech.title"
                    ></span>
                </p>
                <p
                    class="technologies_text text_white"
                    v-html="tech.description"
                ></p>
            </div>
        </div>
    </section>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/swiper-bundle.css';

export default {
    name: 'TechnologiesSection',
    components: { Swiper, SwiperSlide },
    data () {
        return {
            technologies: [
                {
                    title: 'Pick by voice',
                    description:
                        'Программное обеспечение от компании ПрофИТ, призвано управлять подбором заказов, а также перемещениями объектов складирования по средством голосовой технологии. <br>&nbsp;<br>Задача данной технологии – повышение производительности труда в 1,35 раза, за счет освобождения глаз и рук от использования ТСД.',
                },
                {
                    title: 'CUBISCAN',
                    description:
                        'Измерение весогабаритных характеристик товаров, с применением ультразвука. Минимальная погрешность данной технологи, делает возможным учитывать объекты со 100% точностью веса и объёма.',
                },
                {
                    title: 'Паллетный <br>мезонин',
                    description:
                        'Система стеллажного оборудования, позволяющая организовать зону набора не только по полу , но и использовать всю высоту склада.',
                },
                {
                    title: 'Узкопроходная <br>технология',
                    description:
                        'Технология расположения стеллажей, где появляется возможность увеличить емкость склада на 30%, за счет сокращения ширины проходов для работающей складской техники.',
                },
                {
                    title: 'Весовой <br>контроль',
                    description:
                        'Технология контроля<br> скомплектованных<br> заказов, при которой<br> количество возможно<br> допущенных ошибок,<br> стремится к нулю.',
                },
                {
                    title: 'Управление <br>территорией <br>и доками',
                    description:
                        'Модуль управления<br> транспортными<br> средствами прибывшими на<br> разгрузку/погрузку<br> по средствам мобильной<br> связи.',
                },
            ],
            slidesPerView: 4,
        };
    },
};
</script>

<style>
.Triangles_4_mob_v2{
    display: none;
}

@media (max-width: 767px) {
    .Triangles_4_mob_v2{
    display: block;
    position: absolute;
    width: 15.44rem;
    height: 18.69rem;
    top: -12rem;
    right: 0px;
    z-index: -1;
}
}
</style>
