const employeeAccountRoutes = [
    {
        path: '/employee/account',
        name: 'EmployeeAccount',
        component: () => import('@/pages/account/PersonalAccountView.vue'),
        meta: { requiresEmployeeAuth: true },
    },
    {
        path: '/employee/login',
        name: 'EmployeeLogin',
        component: () => import('@/pages/employees/login/EmployeeLogin.vue'),
        meta: { requiresEmployeeAuth: false },
    },
];

export default employeeAccountRoutes;
