<!-- <template>
    <div class="box_vacancies_promotion">
        <p class="characteristics_indentation_subtext text_align_left">
            Расходы на поездку<br />
            за наш счёт!
        </p>
        <p class="promotion_text text_align_right">
            АКЦИЯ
        </p>
    </div>
</template>

<script>
export default {
    name: 'VacancyPromotion',
};
</script> -->
<template>
    <Swiper
        :slides-per-view="slidesPerView"
        :space-between="spaceBetween"
        :loop="false"
        :autoplay="{ delay: 3000, disableOnInteraction: false }"
        :speed="5000"
        :breakpoints="slidesPerView"
        class="technologiesSwiper"
    >
        <SwiperSlide
            v-for="(tech, index) in technologies"
            :key="index"
            class="box_vacancies_promotion"
        >
            <img
                :src="tech.img"
                class="promo_big"
            />
            <p
                class="characteristics_indentation_subtext text_align_left"
                v-html="tech.description"
            >

            </p>

            <p
                class="promotion_text text_align_right"
                v-html="tech.title"
            >

            </p>
        </SwiperSlide>
    </Swiper>
    <!-- <div class="container_technologies">
        <div
            v-for="(tech, index) in technologies"
            :key="index"
            class="block_technologies"
        >
            <p
                class="characteristics_title_meter text_white text_align_center"
                :class="{ height_40px: tech.title.includes('<br>') }"
            >
                <span
                    class="text_white characteristics_title_meter"
                    v-html="tech.title"
                ></span>
            </p>
            <p
                class="technologies_text text_white"
                v-html="tech.description"
            ></p>
        </div>
    </div> -->

</template>

<script>
import { ref, onMounted, onUpdated } from 'vue'; //, onMounted, onUpdated
import { Swiper, SwiperSlide } from 'swiper/vue';
import SwiperCore, { Autoplay } from 'swiper/core';
import 'swiper/swiper-bundle.css';
import promo_big from '@/assets/background/promo_big.svg';

SwiperCore.use([Autoplay]);
export default {
    name: 'VacancyPromotion',
    components: { Swiper, SwiperSlide },
    setup (){
        return {
            SwiperCore,
            modules: {
                Autoplay,
            },
        };
    },
    data () {
        return {
            technologies: [
                {
                    title: 'Акция',
                    description:
                        'Расходы на поездку за наш счёт!',
                    img: promo_big,
                },
                {
                    title: 'Акция',
                    description:
                        'Расходы на поездку за наш счёт!',
                    img: promo_big,
                },
                {
                    title: 'Акция',
                    description:
                        'Расходы на поездку за наш счёт!',
                    img: promo_big,
                },
                {
                    title: 'Акция',
                    description:
                        'Расходы на поездку за наш счёт!',
                    img: promo_big,
                },
            ],
            slidesPerView: 1,
        };
    },
};
</script>

<style scoped>
.characteristics_indentation_subtext{
    font-size: 1.8rem;
font-weight: 700;
line-height: 2.2rem;
}
.promotion_text{
    font-size: 3.2rem;
font-weight: 400;
line-height: 3.2rem;
}
.promo_big{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    object-fit: cover;
    left: 0px;
    object-position: 50%;
    bottom: 0px;
}
@media (max-width: 767px) {
    .swiper{
        display: flex !important;
    }
    .box_vacancies_promotion{
        padding:0.8rem;
        gap: 3.5rem;
    }
    .characteristics_indentation_subtext{
        font-size: 1rem;
font-weight: 700;
line-height: 1.25rem;
    }
    .promotion_text{
        font-size: 2.25rem;
font-weight: 400;
line-height: 2.25rem;
    }
}
</style>
