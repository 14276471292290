<template>
    <section class="section_emploees">
        <div class="header_emploee">
            <p class="characteristics_title_meter">Меню</p>
            <div class="box_button_header_emploee">
                <div class="box_button_back_emploee">
                    <RouterLink
                        to="/employees"
                        class="button_standart"
                    >
                        Вернуться
                    </RouterLink>
                </div>
                <ModalHeader />
            </div>
        </div>
        <div class="section_advanced">
            <div
                v-if="loading"
                class="loading"
            >
                Загрузка...
            </div>
            <div
                v-else-if="error"
                class="error"
            >
                {{ error }}
            </div>
            <template v-else>
                <MenuSection
                    v-for="(section, index) in menuSections"
                    :key="index"
                    :title="section.title"
                    :items="section.items"
                />
            </template>
        </div>
    </section>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import ModalHeader from '@/components/layout/ModalHeader.vue';
import MenuSection from '@/components/employees/MenuSection.vue';
import { API_ENDPOINTS } from '@/utils/constants';

const menuSections = ref([]);
const loading = ref(true);
const error = ref(null);

const fetchMenu = async () => {
    try {
        const response = await fetch(API_ENDPOINTS.DINING_MENU, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        menuSections.value = data;
    } catch (e) {
        error.value = 'Ошибка при загрузке меню: ' + e.message;
        console.error('Ошибка при загрузке меню:', e);
    } finally {
        loading.value = false;
    }
};

onMounted(() => {
    fetchMenu();
});
</script>

<style scoped>
.loading {
    text-align: center;
    padding: 20px;
}

.error {
    color: red;
    text-align: center;
    padding: 20px;
}
</style>
