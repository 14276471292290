<template>
    <div class="box_vacancies">
        <p class="characteristics_s text_white text_align_center text_vacancies_hover_one">
            {{ vacancy.title }}
        </p>
        <div class="box_text_vacancies_display">
            <p class="promotion_text text_white text_align_right">
                {{ vacancy.title }}
            </p>
        </div>
        <p class="technologies_text text_white text_align_right text_vacancies_hover_two">
            Описания вакансии<br />
            (опыт работы, условия,<br />
            график работы)
            Описания вакансии
            (опыт работы, условия,
            график работы)
            Описания вакансии
            (опыт работы, условия,
            график работы)
            Описания вакансии
            (опыт работы, условия,
            график работы)
            Описания вакансии
            (опыт работы, условия,
            график работы)
            Описания вакансии
            (опыт работы, условия,
            график работы)
            Описания вакансии
            (опыт работы, условия,
            график работы)
            Описания вакансии
            (опыт работы, условия,
            график работы)
        </p>
        <div class="box_img_vacancies"></div>
        <div class="box_text_technologies_text_money">
            <p class="technologies_text text_white text_align_center">
                {{ vacancy.salary }}
            </p>
        </div>
        <div class="box_button_display_1200">
            <button
                class="button_standart_white"
                @click="$emit('apply')"
            >
                откликнуться
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'VacancyDetails',
    props: {
        vacancy: {
            type: Object,
            required: true,
        },
    },
};
</script>
