<template>
    <div class="box_advanced">
        <img
            :src="employee.image"
            class="img_advanced"
            :alt="employee.name"
        />
        <div class="box_text_advanced">
            <p class="characteristics_indentation_subtext_box5 text_align_right">
                {{ employee.name }}
            </p>
            <p class="font_16_500_Montserrat_Medium color_orange text_align_right">
                {{ employee.achievement }}
            </p>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        employee: {
            type: Object,
            required: true,
        },
    },
};
</script>
