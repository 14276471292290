<template>
    <section class="about_section">
        <!-- <img
            src="@/assets/background/Line_1.svg"
            class="line_1"
        />
         <img
            src="@/assets/background/Line_1_mob.svg"
            class="line_1_mob"
        />
        <img
            src="@/assets/background/Line_2.svg"
            class="line_2"
        />
         -->
        <img
            src="@/assets/background/Triangles_1.svg"
            class="Triangles_1"
        />
        <img
            src="@/assets/background/Triangles_2.svg"
            class="Triangles_2"
        />
        <img
            src="@/assets/background/Triangles_1_mob.svg"
            class="Triangles_1_mob"
        />
        <img
            src="@/assets/background/Triangles_2_mob.svg"
            class="Triangles_2_mob"
        />
        <div class="about_container">
            <div class="about_box_title">
                <h1 class="about_title text_align_left">
                    Заголовок заголовок
                </h1>
                <h2 class="about_title text_align_right">
                    заголовок заголовок
                </h2>
            </div>
            <p class="about_subtitle">
                Подзаголовок подзаголовок подзаголовок подзаголовок<br />
                Подзаголовок подзаголовок подзаголовок подзаголовок подза
            </p>
        </div>
        <div class="about_element_elepse"></div>
        <img
            src="@/assets/elements/Ellipse_2.svg"
            class="about_element_elepse_2"
            alt="Ellipse 2"
        />
    </section>
</template>

<script>
export default {
    name: 'AboutSection',
};
</script>

<style scoped>
.Triangles_1{
width: 19.1rem;
height: 11.9rem;
top: -11.95rem;
left: 0px;
position: absolute;
}
.Triangles_2{
    width: 16.7rem;
height: 11.95rem;
top: -11.95rem;
right: 0px;
position: absolute;
}
.Triangles_1_mob,
.Triangles_2_mob{
display: none;
}
@media (max-width: 767px) {
.Triangles_1,
.Triangles_2{
display: none;
}
.Triangles_1_mob,
.Triangles_2_mob{
display: block;
position: absolute;
}
.Triangles_1_mob{
width: 13.99rem;
height: 7.94rem;
top: -7.94rem;
}
.Triangles_2_mob{
width: 100%;
height: 7.88rem;
bottom: -7.88rem;
z-index: -1;

right: -0.2rem;
}
}
</style>
