<template>
    <div
        class="burger_button"
        :class="{ main: isActiveRoute }"
        @click="toggleModal"
    >
        <div class="burger_line_1"></div>
        <div class="burger_line_2"></div>
        <div class="burger_line_3"></div>
    </div>
    <div
        ref="modal"
        class="modal"
        @click="closeModal"
    >
        <!-- v-if="showModal" -->
        <div
            class="modal_container"
            @click.stop
        >
            <div class="modal-content">
                <router-link
                    to="/about"
                    class="burger_nav"
                    @click="closeModal"
                >
                    КЛИЕНТАМ
                </router-link>
                <router-link
                    to="/vacancies"
                    class="burger_nav magrin_left_85px"
                    @click="closeModal"
                >
                    СОИСКАТЕЛЯМ
                </router-link>
                <router-link
                    to="/employees"
                    class="burger_nav"
                    @click="closeModal"
                >
                    СОТРУДНИКАМ
                </router-link>
            </div>
            <div class="box_burger_nav_lc">
                <router-link
                    to="/employee/login"
                    class="burger_nav_lc"
                    @click="closeModal"
                >
                    ВХОД
                </router-link>
                <router-link
                    to="/"
                    class="burger_nav_lc"
                    @click="closeModal"
                >
                    МЕНЮ
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data () {
        return {
            // showModal: false,
        };
    },
    computed: {
        isActiveRoute () {
            const activeRoutes = ['/', '/about']; // Ваши маршруты

            return activeRoutes.includes(this.$route.path);
        },
    },
    methods: {
        toggleModal () {
            this.showModal = !this.showModal;
            this.$refs.modal.classList.toggle('active');
            this.toggleBurgerClasses();
            document.body.classList.toggle('no-scroll');
        },
        closeModal () {
            this.showModal = false;
            this.toggleBurgerClasses();
            this.$refs.modal.classList.remove('active');
            document.body.classList.remove('no-scroll');
        },
        toggleBurgerClasses () {
            const burgerButton = document.querySelector('.burger_button');
            const burgerLines = document.querySelectorAll('.burger_line');

            if (this.showModal) {
                burgerButton.classList.add('active');
                burgerLines.forEach((line) => line.classList.add('active'));
            } else {
                burgerButton.classList.remove('active');
                burgerLines.forEach((line) => line.classList.remove('active'));
            }
        },
    },
};
</script>

<style>
.burger_button.main {
    border: 0.025rem solid rgb(25, 25, 25, 0);
    background-color: #ece4de;
}
/* @media (max-width: 767px) {
    .burger_button.main {
    border: 0.025rem solid rgb(25, 25, 25);
    background-color: #f5f5f5;
}
} */
</style>
