<template>
    <section class="section_map_title">
        <!-- <img
            src="@/assets/background/Line_7.svg"
            class="line_7"
        />
        <img
            src="@/assets/background/Line_7_mob.svg"
            class="line_7_mob"
        /> -->
        <h3 class="characteristics_title text_align_center margin_bottom_64px">
            Местоположение.
        </h3>
        <div class="section_map">
            <!-- <img
                src="@/assets/background/Line_8.svg"
                class="line_8"
            /> -->
            <img
                src="@/assets/background/Triangles_7.png"
                class="Triangles_7"
            />
            <img
                src="@/assets/background/Triangles_7_mob_v2.svg"
                class="Triangles_7_mob_v2"
            />
            <div class="box_map">
                <img
                    src="@/assets/background/Triangles_7_mob.svg"
                    class="Triangles_7_mob"
                />
                <div
                    id="map"
                    class="map"
                ></div>
            </div>
            <div class="container_text_map">
                <div class="box_text_map">
                    <p class="characteristics_indentation_subtext_box5 letter-spacing-1">
                        Логистический комплекс ТД Шкуренко расположен в Кировском округе г. Омска, в непосредственной
                        близости от федеральной трассы М-51, удобные подъездные асфальтированные пути.
                    </p>
                </div>
                <div class="box_subtext_map">
                    <p class="technologies_text">
                        В настоящее время любому складу требуется надёжная система доступа и контроля, ЛК не
                        исключение:<br />
                        &nbsp;<br />
                        регистрация прибывших ТС в системе WMS, шлагбаум оборудован видео фиксацией государственного
                        номера, приглашение водителя на разгрузку /погрузку по средством мобильной связи.
                    </p>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'MapSection',
    data () {
        return {
            mapApiLoaded: false,
            mapCenter: [54.908060, 73.299015], // координаты 2-я Казахстанская ул., 46/1, Омск
        };
    },
    mounted () {
        this.loadYandexMapApi()
            .then(() => {
                this.initMap();
            })
            .catch(error => {
                console.error('Failed to load Yandex Maps API:', error);
            });
    },
    methods: {
        loadYandexMapApi () {
            return new Promise((resolve, reject) => {
                if (this.mapApiLoaded) {
                    resolve();

                    return;
                }

                const script = document.createElement('script');

                script.src = 'https://api-maps.yandex.ru/2.1/?lang=ru_RU';
                script.async = true;
                script.onload = () => {
                    this.mapApiLoaded = true;
                    resolve();
                };

                script.onerror = reject;
                document.head.appendChild(script);
            });
        },
        initMap () {
            window.ymaps.ready(() => {
                const map = new window.ymaps.Map('map', {
                    center: this.mapCenter,
                    zoom: 16, // Увеличил зум для лучшего обзора местности
                });

                // Создаем метку
                const placemark = new window.ymaps.Placemark(this.mapCenter, {
                    hintContent: 'ТД Шкуренко',
                    balloonContent: 'Логистический комплекс ТД Шкуренко<br>2-я Казахстанская ул., 46/1, Омск',
                });

                // Добавляем метку на карту
                map.geoObjects.add(placemark);
            });
        },
    },
};
</script>

<style scoped>
.Triangles_7{
    position: absolute;
    z-index: -1;
    width: 24.5rem;
height: 81.05rem;
right: 0px;
bottom: 0px;
}
.Triangles_7_mob,
.Triangles_7_mob_v2{
    display: none;
}
@media (max-width: 767px) {
.Triangles_7{
display: none;
}
.Triangles_7_mob{
    display: block;
    position: absolute;
    z-index: -1;
    right: 0px;
    top: -7.94rem;
    width: 23.13rem;
height: 7.94rem;
}
.Triangles_7_mob_v2{
    display: block;
    position: absolute;
    z-index: -1;
    width: 100%;

bottom: 0px;
right: 0px;
}
}
</style>
