<template>
    <section class="characteristics_section">
        <!-- <img
            src="@/assets/background/Line_3.svg"
            class="line_3"
        /> -->
        <img
            src="@/assets/background/Triangles_4.png"
            class="Triangles_4"
        />
        <img
            src="@/assets/background/Triangles_4_mob.png"
            class="Triangles_4_mob"
        />
        <h3 class="characteristics_title text_align_center">
            Характеристика ЛК
        </h3>

        <div class="characteristics_container">
            <div class="characteristics_box_in_box">
                <div class="characteristics_box_1">
                    <div class="characteristics_box_360">
                        <div class="characteristics_box">
                            <p class="characteristics_360_text">
                                295
                            </p>
                            <p class="characteristics_360_subtext">
                                видеокамер
                            </p>
                        </div>
                    </div>
                    <div class="characteristics_indentation_box">
                        <p class="characteristics_indentation_text text_align_right">
                            СИСТЕМА<br />
                            пожарной<br />
                            сигнализации
                        </p>
                    </div>
                </div>
                <div class="characteristics_box_2">
                    <div>
                        <p class="characteristics_s text_align_center">
                            S
                        </p>
                        <p class="characteristics_title_meter text_align_center">
                            20 000 м&#178;
                        </p>
                    </div>
                    <div class="characteristics_text_margin">
                        <p class="characteristics_indentation_text text_align_center">
                            Ёмкость хранения<br />
                            36 000 п. м.<br />
                            &nbsp;
                            <br />
                            11 500 ячеек<br />
                            полного хранения
                        </p>
                    </div>
                    <div>
                        <p class="characteristics_indentation_text text_align_center">
                            Высота потолка
                        </p>
                        <p class="characteristics_s text_align_center">
                            13 м
                        </p>
                    </div>
                </div>
            </div>
            <div class="characteristics_box_in_box_2">
                <div class="characteristics_box_3">
                    <p class="characteristics_indentation_text text_align_left">
                        Пол с антипылевым покрытием с нагрузкой 8000 кг на м&#178;
                    </p>
                </div>
                <div class="characteristics_box_4">
                    <div class="characteristics_stock_box">
                        <p class="characteristics_indentation_text text_align_left letter-spacing-1">
                            Мультитемпературный
                        </p>
                        <p class="characteristics_indentation_subtext">
                            склад
                        </p>
                    </div>
                    <p class="characteristics_indentation_subtext text_align_right">
                        <span class="characteristics_indentation_text">7 зон</span>
                        с различными температурными режимами
                    </p>
                </div>
                <div class="characteristics_box_5">
                    <p class="characteristics_indentation_subtext_box5">
                        30 ед. автоматических ворот с тепловыми завесами, доклевеллерами и
                        докшелтерами
                    </p>
                </div>
                <div class="characteristics_box_6">
                    <p class="characteristics_indentation_subtext text_align_right">
                        Производственный участок
                    </p>
                    <p class="characteristics_indentation_subtext text_align_right">
                        Зона под лицензируемую продукцию
                    </p>
                    <p class="characteristics_indentation_subtext text_align_right">
                        Зона фруктов и овощей оборудована камерами дозревания бананов
                        четвертого поколения
                    </p>
                </div>
                <div class="characteristics_box_7">
                    <p class="characteristics_s text_align_center">
                        КЛАСС А+
                    </p>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'CharacteristicsSection',
};
</script>

<style scoped>
.Triangles_4{
position: absolute;
width: 43.75rem;
height: 74.79rem;
z-index: -2;
left: 0px;
top: -27rem;
}
.Triangles_4_mob{
    display: none;
}
@media (max-width: 767px) {
.Triangles_4{
    display: none;
}
.Triangles_4_mob{
    display: block;
     width: 20rem;
    height: 36.94rem;
    position: absolute;
    z-index: -1;
    right: 0px;
    top: -6.5rem;
}
}
</style>
