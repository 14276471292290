<template>
    <footer>
        <section class="section_footer_black">
            <div class="container_footer_nav">
                <p class="footer_title text_white">LOGO</p>
                <div class="box_footer_nav">
                    <router-link
                        to="/"
                        class="technologies_text footer_nav"
                    >
                        ГЛАВНАЯ
                    </router-link>
                    <router-link
                        to="/about"
                        class="technologies_text footer_nav"
                    >
                        О КОМПАНИИ
                    </router-link>
                    <router-link
                        to="/"
                        class="technologies_text footer_nav"
                    >
                        СОИСКАТЕЛЯМ
                    </router-link>
                    <router-link
                        to="/"
                        class="technologies_text footer_nav"
                    >
                        СОТРУДНИКАМ
                    </router-link>
                    <router-link
                        to="/"
                        class="technologies_text footer_nav"
                    >
                        КОНТАКТЫ
                    </router-link>
                </div>
            </div>
            <div class="footer_box_contacts">
                <a
                    href="mailto:logocompay@mail.com"
                    class="technologies_text footer_nav"
                >
                    logocompay@mail.com
                </a>
                <a
                    href="tel:+74993509027"
                    class="technologies_text footer_nav"
                >
                    +7 (499) 350-90-27
                </a>
            </div>
        </section>
        <section class="section_footer_white">
            <p class="subtext_services footer_text">
                ОГРН 117774699999 / ИНН 7729999999
            </p>
            <p class="subtext_services footer_text text_decoration_underline">
                ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ
            </p>
        </section>
    </footer>
</template>

<script>
export default {
    components: {},
};
</script>
