<template>
    <section class="services_section">
        <h3 class="characteristics_title text_align_center">Услуги 3PL</h3>
        <div class="services_box_info">
            <p class="characteristics_indentation_subtext_box5 services_width_info letter-spacing-1">
                Компания ТД Шкуренко – крупнейший дистрибьютор<br />
                товаров народного потребления. ЛК будет<br />
                инструментом для достижения цели - стать<br />
                лидером в логистической отрасли Омского региона.
            </p>

            <p class="characteristics_indentation_subtext_box5 services_width_info2 letter-spacing-1">
                Быстрорастущее направление - это 3PL услуги, которые в наше время
                пользуются большим спросом.
            </p>
        </div>
        <div class="container_services_block">
            <div class="box_services_left">
                <div class="services_block_1">
                    <div class="box_title_services">
                        <p class="number_box_services">01</p>
                        <p class="number_box_services">Решения для клиентов</p>
                    </div>
                    <div class="box_subtext_services">
                        <p class="subtext_services text_align_right">
                            · Использование многофункциональной<br />
                            системы управления складом LEAD WMS.
                        </p>
                        <p class="subtext_services text_align_right">
                            · Индивидуальный расчёт тарифов <br />
                            под потребности клиента.
                        </p>
                    </div>
                </div>
                <div class="services_block_2">
                    <div class="box_title_services">
                        <p class="number_box_services">02</p>
                        <p class="number_box_services">Складская обработка</p>
                    </div>
                    <div class="box_subtext_services">
                        <p class="subtext_services text_align_right">· Приёмка</p>
                        <p class="subtext_services text_align_right">· Комплектация</p>
                        <p class="subtext_services text_align_right">· Отгрузка</p>
                        <p class="subtext_services text_align_right">· - Кросс - докинг</p>
                    </div>
                </div>
            </div>
            <div class="box_services_center">
                <div class="services_block_3">
                    <div class="box_title_services">
                        <p class="number_box_services">03</p>
                        <p class="number_box_services">Городская и региональная доставка</p>
                    </div>
                    <div class="box_subtext_services">
                        <p class="subtext_services text_align_right">
                            · Региональная доставка
                        </p>
                        <p class="subtext_services text_align_right">
                            · Городская доставка от адреса до адреса
                        </p>
                        <p class="subtext_services text_align_right">
                            · Возможность организации<br />
                            индивидуальной доставки
                        </p>
                    </div>
                </div>
                <div class="services_block_4">
                    <div class="box_title_services">
                        <p class="number_box_services">04</p>
                        <p class="number_box_services">Ответственное хранение</p>
                    </div>
                    <div class="box_subtext_services">
                        <p class="subtext_services text_align_right">
                            · Товар на хранение принимается<br />
                            по артикулам, срокам годности, партиям.
                        </p>
                        <p class="subtext_services text_align_right">
                            · Доступно стеллажное хранение,<br />
                            типа мезонин, фронтальное.
                        </p>
                        <p class="subtext_services text_align_right">
                            · Возможность выбора температурного режима.<br />
                            От -25°С до +20°С
                        </p>
                    </div>
                </div>
            </div>
            <div class="box_services_right">
                <div class="box_title_services">
                    <p class="number_box_services">05</p>
                    <p class="number_box_services">
                        Дополнительные<br />
                        складские услуги
                    </p>
                </div>
                <div class="box_subtext_services">
                    <p class="subtext_services text_align_right">
                        Расширенный комплекс услуг под индивидуальные потребности клиента:
                    </p>
                    <p class="subtext_services_light text_align_right">
                        оформление документов
                    </p>
                    <p class="subtext_services_light text_align_right">
                        взвешивание и измерение габаритов
                    </p>
                    <p class="subtext_services_light text_align_right">инвентаризация</p>
                    <p class="subtext_services_light text_align_right">маркировка</p>
                    <p class="subtext_services_light text_align_right">стикеровка</p>
                    <p class="subtext_services_light text_align_right">упаковка</p>
                    <p class="subtext_services_light text_align_right">фасовка</p>
                    <p class="subtext_services_light text_align_right">переборка</p>
                    <p class="subtext_services_light text_align_right">сортировка</p>
                    <p class="subtext_services_light text_align_right">пломбирование</p>
                </div>
            </div>
            <!-- <img
                src="@/assets/elements/Vector_3pl.svg"
                class="vector_3pl"
            />
            <img
                src="@/assets/elements/Vector_3pl_v2.svg"
                class="vector_3pl_v2"
            /> -->
        </div>
        <img
            src="@/assets/background/Triangles_3.png"
            class="Triangles_3"
        />
        <img
            src="@/assets/background/Triangles_3_mob.png"
            class="Triangles_3_mob"
        />
        <img
            src="@/assets/background/Triangles_3_mob_v2.png"
            class="Triangles_3_mob_v2"
        />
    </section>
</template>

<script>
export default {
    name: 'ServicesSection',
};
</script>

<style scoped>

.Triangles_3{
width: 35rem;
height: 60rem;
position: absolute;
z-index: -2;
right: 0px;
top: -6.6rem;
}
.Triangles_3_mob,
.Triangles_3_mob_v2{
    display: none;
}
@media (max-width: 767px) {
.Triangles_3{
    display: none;
}
.Triangles_3_mob{
    display: block;
    position: absolute;
    z-index: -2;
    width: 23.06rem;
height: 47.81rem;
right: 0px;
top: -7.5rem;
}
.Triangles_3_mob_v2{
    display: block;
    position: absolute;
    z-index: -2;
    width: 23.13rem;
height: 57.88rem;
left: 0px;
bottom: 12rem;
}
}
</style>
