const adminRoutes = [
    {
        path: '/admin',
        component: () => import('@/pages/admin/AdminView.vue'),
        children: [
            { path: '', redirect: '/admin/dashboard', meta: { requiresAdminAuth: true } },
            { path: 'dashboard', component: () => import('@/pages/admin/dashboard/DashboardView.vue'), meta: { requiresAdminAuth: true } },
            { path: 'users', component: () => import('@/pages/admin/users/UsersView.vue'), meta: { requiresAdminAuth: true } },
            { path: 'vacancies', component: () => import('@/pages/admin/vacancies/VacanciesView.vue'), meta: { requiresAdminAuth: true } },
            { path: 'leaders', component: () => import('@/pages/admin/leaders/LeadersView.vue'), meta: { requiresAdminAuth: true } },
            { path: 'work-schedule', component: () => import('@/pages/admin/workSchedule/WorkScheduleView.vue'), meta: { requiresAdminAuth: true } },
            { path: 'work-rest-mode', component: () => import('@/pages/admin/workRestMode/WorkRestModeView.vue'), meta: { requiresAdminAuth: true } },
            { path: 'management-contacts', component: () => import('@/pages/admin/managementContacts/ManagementContactsView.vue'), meta: { requiresAdminAuth: true } },
            { path: 'employee-duties', component: () => import('@/pages/admin/employeeDuties/EmployeeDutiesView.vue'), meta: { requiresAdminAuth: true } },
        ],
        meta: { requiresAdminAuth: true },
    },
    {
        path: '/admin/login',
        component: () => import('@/pages/admin/auth/LoginView.vue'),
        meta: { requiresAdminAuth: false },
    },
];

export default adminRoutes;
