<template>
    <section class="equipment_section">
        <!-- <img
            src="@/assets/background/Line_5.svg"
            class="line_5"
        /> <img
            src="@/assets/background/Line_9.svg"
            class="line_9"
            />
        <img
            src="@/assets/background/Line_10.svg"
            class="line_10"
        /> -->
        <img
            src="@/assets/background/Triangles_5.svg"
            class="Triangles_5"
        />
        <img
            src="@/assets/background/Triangles_5_mob.png"
            class="Triangles_5_mob"
        />
        <div class="equipment_box_title">
            <h3 class="characteristics_title text_align_center">
                Подъемно-транспортное оборудование.
            </h3>
            <p class="characteristics_indentation_subtext_box5 equipment_width_subtext text_align_center letter-spacing-1">
                Комплексное оснащение ЛК техникой от JUNGHEINRICH, позволяет справляться
                с задачами любого уровня.
            </p>
        </div>
        <div class="container_technologies">
            <div class="block_equipment">
                <div class="box_text_equipment">
                    <p class="technologies_text text_white text_align_center height_40px">
                        Узкопроходный<br />
                        штабелер
                    </p>
                    <p class="characteristics_title_meter text_white text_align_center">
                        EKX514
                    </p>
                </div>
                <p class="technologies_text text_white">
                    Предназначен для работы в узкопроходной зоне по индуктивной
                    направляющей, с подъемом кабины оператора на h = 11.5 метров.
                </p>
            </div>
            <div
                class="block_equipment"
                @mouseover="hoverImage(1)"
                @mouseout="resetImage"
            >
                <div class="box_text_equipment">
                    <p class="technologies_text text_white text_align_center height_40px">
                        Ричтрак
                    </p>
                    <p class="characteristics_title_meter text_white text_align_center">
                        ETV318
                    </p>
                </div>
                <p class="technologies_text text_white">
                    Предназначен для обслуживания разных видов стеллажей с min AST = 2,8
                    м., высота подъёма 11,5 м., стрела оборудована видеофиксацией высоты.
                </p>
            </div>
            <div
                class="block_equipment"
                @mouseover="hoverImage(2)"
                @mouseout="resetImage"
            >
                <div class="box_text_equipment">
                    <p class="technologies_text text_white text_align_center height_40px">
                        Погрузчик
                    </p>
                    <p class="characteristics_title_meter text_white text_align_center">
                        EFG215
                    </p>
                </div>
                <p class="technologies_text text_white"></p>
            </div>
            <div
                class="block_equipment"
                @mouseover="hoverImage(3)"
                @mouseout="resetImage"
            >
                <div class="box_text_equipment">
                    <p class="technologies_text text_white text_align_center height_40px">
                        Паллетоперевозчик
                    </p>
                    <p class="characteristics_title_meter text_white text_align_center">
                        ESE 120K
                    </p>
                </div>
                <p class="technologies_text text_white"></p>
                <img
                    ref="image"
                    class="geometry_quipment"
                    src="@/assets/elements/geometry.svg"
                />
            </div>
        </div>
    </section>
</template>

<script>
export default {
    methods: {
        hoverImage () {
            this.$refs.image.style.filter = 'brightness(50%)';
        },
        resetImage () {
            this.$nextTick(() => {
                this.$refs.image.style.filter = 'brightness(100%)';
            });
        },
    },
};
</script>

<style scoped>
.Triangles_5{
    width: 24.55rem;
height: 12.05rem;
position: absolute;
right: 0px;
z-index: -1;
top: -3rem;
}
.Triangles_5_mob{
    display: none;
}
@media (max-width: 767px) {
    .Triangles_5{
        display: none;
    }
    .Triangles_5_mob{
    display: block;
    position: absolute;
    z-index: -1;
    right: 0px;
    top: 0px;
}
}
</style>
