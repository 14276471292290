<template>
    <div
        ref="parallaxContainer"
        class="parallax-container"
        @mousemove="onMouseMove"
        @mouseleave="resetParallax"
    >
        <svg
            class="line"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
        >
            <path
                :d="linePath"
                stroke="black"
                stroke-width="1"
                fill="none"
            />
        </svg>
    </div>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted } from 'vue';

const mouseX = ref(50);
const containerWidth = ref(100);
const targetOffsetX = ref(0);
const currentOffsetX = ref(0);
const parallaxContainer = ref(null);

const linePath = computed(() => {
    const offsetX = currentOffsetX.value;

    return `M 50 0 C ${50 + offsetX} 25 ${50 + offsetX} 75 50 100`;
});

onMounted(() => {
    updateContainerWidth();
    window.addEventListener('resize', updateContainerWidth);
    animateLine();
});

onUnmounted(() => {
    window.removeEventListener('resize', updateContainerWidth);
});

function updateContainerWidth () {
    containerWidth.value = parallaxContainer.value.offsetWidth;
}

function onMouseMove (event) {
    const containerRect = parallaxContainer.value.getBoundingClientRect();

    mouseX.value = event.clientX - containerRect.left;
    updateTargetOffsetX();
}

function resetParallax () {
    targetOffsetX.value = 0;
}

function updateTargetOffsetX () {
    const tension = 0.27;

    targetOffsetX.value = ((mouseX.value - containerWidth.value / 2) / containerWidth.value) * 100 * tension * 5;
}

function animateLine () {
    currentOffsetX.value += (targetOffsetX.value - currentOffsetX.value) * 0.1;
    requestAnimationFrame(animateLine);
}
</script>
