<template>
    <div class="section_header">
        <router-link
            to="/"
            class="characteristics_title"
        >
            Logo
        </router-link>
        <p class="characteristics_title text_align_center title_header_one">
            {{ title }}
        </p>
        <ModalHeader />
    </div>
    <p class="characteristics_title text_align_center title_header_two">
        {{ title }}
    </p>
</template>

<script>
import ModalHeader from '@/components/layout/ModalHeader.vue';
export default {
    components: { ModalHeader },
    props: {
        title: String,
    },
    data () {
        return {
            showModal: false,
        };
    },
    methods: {
        toggleModal () {
            this.showModal = !this.showModal;
            this.toggleBurgerClasses();
            document.body.classList.toggle('no-scroll');
        },
        closeModal () {
            this.showModal = false;
            this.toggleBurgerClasses();
            document.body.classList.remove('no-scroll');
        },
        toggleBurgerClasses () {
            const burgerButton = document.querySelector('.burger_button');
            const burgerLines = document.querySelectorAll('.burger_line');

            if (this.showModal) {
                burgerButton.classList.add('active');
                burgerLines.forEach((line) => line.classList.add('active'));
            } else {
                burgerButton.classList.remove('active');
                burgerLines.forEach((line) => line.classList.remove('active'));
            }
        },
    },
};
</script>
