<template>
    <div class="section_ticker">
        <!-- <img
            src="@/assets/background/Line_6.svg"
            class="line_6"
        />
        <img
            src="@/assets/background/Line_6_mob.svg"
            class="line_6_mob"
        /> -->
        <img
            src="@/assets/background/Triangles_6.png"
            class="Triangles_6"
        />
        <img
            src="@/assets/background/Triangles_6_mob.png"
            class="Triangles_6_mob"
        />
        <h3 class="characteristics_title text_align_center">Партнеры</h3>
        <div>
            <MarqueeRow
                v-for="(row, index) in rows"
                :key="index"
                :ids="row.ids"
                :reverse="row.reverse"
            />
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import MarqueeRow from './MarqueeRow.vue';

export default defineComponent({
    name: 'TickerAbout',
    components: { MarqueeRow },
    data () {
        return {
            rows: [
                { ids: ['marquee1', 'marquee2'], reverse: true },
                { ids: ['marquee3', 'marquee4'], reverse: false },
                { ids: ['marquee5', 'marquee6'], reverse: true },
            ],
        };
    },
});
</script>

<style>
.wrap {
  max-width: 100%;
  margin: auto;
  padding: 96px 0px 53px 0px;
}

.items-wrap {
  position: relative;
  display: flex;
  overflow: hidden;
  user-select: none;
  gap: 1rem;
}

.items-wrap:before,
.items-wrap:after {
  /* content: ""; */
  height: 100%;
  top: 0;
  width: 10%;
  position: absolute;
  z-index: 1;
  pointer-events: none;
}

.items-wrap:before {
  left: 0;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}

.items-wrap:after {
  right: 0;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
}

.items {
  flex-shrink: 0;
  display: flex;
  gap: 1.5rem;
  counter-reset: item;
  justify-content: space-around;
  min-width: 100%;
}

.item {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  counter-increment: item;
  border-radius: 0.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  transition: all 0.1s ease-in-out;
  font-family: Rostov;
  font-size: 4.8rem;
  font-weight: 400;
  line-height: 4.8rem;
  letter-spacing: 2%;
  text-align: left;
  text-transform: uppercase;
  color: rgb(245, 245, 245);
  -webkit-text-stroke: 1px black;
  /* Ширина и цвет бордера вокруг текста для браузеров на основе WebKit */
  transition: 0.3s ease;
}

.item:hover {
  color: rgb(25, 25, 25);
}

.marquee {
  animation: scroll 20s linear infinite;
}

.reverce {
  animation-direction: reverse;
}
.Triangles_6{
  width: 26.35rem;
height: 59.9rem;
position: absolute;
z-index: -1;
left: 0px;
top: -12rem;
}
@keyframes scroll {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(calc(-100% - 1rem));
  }
}

@media (max-width: 991px) {
  .item {
    font-size: 4rem;
    font-weight: 400;
    line-height: 4rem;
  }
}

.marquee:hover {
  animation-play-state: paused;
  /* Останавливаем анимацию при наведении */
}
.Triangles_6_mob{
    display: none;
  }
@media (max-width: 767px) {
  .Triangles_6{
    display: none;
  }
  .Triangles_6_mob{
    display: block;
    position: absolute;
    width: 23.13rem;
height: 53rem;
z-index: -1;
top: -10.375rem;
  }
}
</style>
