const API_BASE_URL = process.env.VUE_APP_API_URL;

const createEndpoint = (path) => `${API_BASE_URL}${path}`;

const API_PATHS = {
    LOGIN: '/employee/login',
    MANAGEMENT_CONTACTS: '/management-contacts',
    EMPLOYEE_DUTIES: '/employee-duties',
    EMPLOYEE_INSTRUCTIONS: '/employee-instructions',
    VACATION_DAYS: '/vacation-days/external',
    DEBTS_ANY: '/debts-any/external',
    DEBTS_DOCS: '/debts-docs/external',
    WORK_SCHEDULE: '/work-schedule',
    LEADERS: '/leaders',
    EMPLOYEE: '/employee',
    VACANCIES: '/vacancies',
    WORK_REST_SETTINGS: '/work-rest-settings',
    DINING_MENU: '/dining-menu',
};

export const API_ENDPOINTS = Object.freeze(
    Object.entries(API_PATHS).reduce((acc, [key, path]) => ({
        ...acc,
        [key]: createEndpoint(path),
    }), {}),
);

export const SCAN_STATUSES = Object.freeze({
    READY: 'Готов к сканированию',
    SCANNING: 'Сканирование...',
    NUMBER_FOUND: 'Номер найден',
    NUMBER_NOT_FOUND: 'Номер не найден',
    ERROR: 'Ошибка сканирования',
    CAMERA_ERROR: 'Ошибка доступа к камере',
});

export const CACHE_LIFETIME = Object.freeze(20 * 60 * 1000);

export const CACHE_KEYS = Object.freeze({
    DEBTS: 'debts_',
    MANAGEMENT_CONTACTS: 'management_contacts_',
    EMPLOYEE_DUTIES: 'employee_duties_',
    VACATION_DAYS: 'vacation_days_',
    WORK_SCHEDULE: 'work-schedule_',
});

export const DEBT_TYPES = Object.freeze({
    ANY: 'any',
    DOCS: 'docs',
});

export const ERROR_MESSAGES = Object.freeze({
    FETCH_DEBTS_ANY: 'Ошибка при получении данных о задолженностях сотрудника. Пожалуйста, попробуйте позже.',
    FETCH_DEBTS_DOCS: 'Ошибка при получении данных о документообороте сотрудника. Пожалуйста, попробуйте позже.',
    FETCH_TOTAL: 'Ошибка при получении общей суммы задолженностей сотрудника. Пожалуйста, попробуйте позже.',
    FETCH_VACATION_DAYS: 'Ошибка при получении данных о накопленных днях отпуска сотрудника. Пожалуйста, попробуйте позже.',
    FETCH_DUTIES: 'Ошибка при загрузке должностных обязанностей сотрудника. Пожалуйста, попробуйте позже.',
    FETCH_WORK_SCHEDULE: 'Ошибка при загрузке графика сотрудника. Пожалуйста, попробуйте позже.',
    FETCH_MANAGEMENT_CONTACTS: 'Ошибка при загрузке контактов руководства. Пожалуйста, попробуйте позже.',
    DOWNLOAD_INSTRUCTIONS: 'Ошибка при скачивании инструкции. Пожалуйста, попробуйте позже.',
    COPY: 'Ошибка при копировании. Пожалуйста, попробуйте позже.',
});

export const MONTHS = Object.freeze([
    'Январь', 'Февраль', 'Март', 'Апрель',
    'Май', 'Июнь', 'Июль', 'Август',
    'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь',
]);

export const DAYS_OF_WEEK = Object.freeze(['ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ', 'ВС']);
