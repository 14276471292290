<template>
    <section class="section_emploees">
        <div class="header_emploee">
            <p class="characteristics_title_meter">
                Расписание служебного транспорта
            </p>
            <div class="box_button_header_emploee">
                <div class="box_button_back_emploee">
                    <RouterLink
                        to="/employees/schedule"
                        class="button_standart"
                    >
                        Вернуться
                    </RouterLink>
                </div>
                <ModalHeader />
            </div>
        </div>
        <div class="section_map_there">
            <div class="section_there_schedule">
                <div class="box_dropdown_list_v2">
                    <p class="number_box_services">
                        {{ routeTitle }}
                    </p>
                    <div class="box_dropdown_list_v3">
                        <div
                            ref="box_list_button_schedule"
                            class="box_list_button_schedule"
                        >
                            <div
                                ref="circle_button_list"
                                class="circle_button_list"
                                @click="toggleModal"
                            ></div>
                            <div class="box_ferst_button_list">
                                <p class="technologies_text">{{ activeDay }}</p>
                            </div>
                            <p
                                v-for="day in days"
                                :key="day"
                                :class="{ active: day === activeDay }"
                                class="technologies_text"
                                @click="setActiveDay(day)"
                            >
                                {{ day }}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="container_time_there_schedule">
                    <div class="box_time_schedule">
                        <p
                            v-for="time in scheduleForDay"
                            :key="time"
                            class="font_16_Montserrat_Light color_orange"
                        >
                            {{ time }}
                        </p>
                        <p
                            v-if="scheduleForDay.length === 0"
                            class="font_16_Montserrat_Light"
                        >
                            Нет поездов
                        </p>
                    </div>
                </div>
                <div class="container_stops">
                    <button
                        class="box_button_stops"
                        @click="toggleDirection"
                    >
                        <img
                            src="@/assets/elements/top_bottom.svg"
                            class="img_button_stops"
                            alt="Toggle direction"
                        />
                    </button>
                    <div
                        class="box_stops"
                        :style="{ 'flex-direction': isThere ? 'column' : 'column-reverse' }"
                    >
                        <div
                            v-for="stop in currentStops"
                            :key="stop"
                            class="box_text_stops"
                        >
                            <div class="orange_circle"></div>
                            <p class="font_16_Montserrat_Light">{{ stop }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <img
                :src="currentMapImage"
                class="img_map"
                :alt="isThere ? 'Path there' : 'Path back'"
            />
        </div>
    </section>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import ModalHeader from '@/components/layout/ModalHeader.vue';

export default {
    components: { ModalHeader },
    setup () {
        const route = useRoute();
        const activeDay = ref('');
        const isThere = ref(true);
        const scheduleData = ref(null);
        const box_list_button_schedule = ref(null);
        const circle_button_list = ref(null);

        const days = [
            'Понедельник',
            'Вторник',
            'Среда',
            'Четверг',
            'Пятница',
            'Суббота',
            'Воскресенье',
        ];

        const dayMapping = {
            Понедельник: 'monday',
            Вторник: 'tuesday',
            Среда: 'wednesday',
            Четверг: 'thursday',
            Пятница: 'friday',
            Суббота: 'saturday',
            Воскресенье: 'sunday',
        };

        const routeConfig = computed(() => {
            const configs = {
                pervomaisky: {
                    title: 'Первомайский - Лог. Комплекс',
                    stops: [
                        'Первомайский',
                        'Химик',
                        'ДК Звёздный',
                        'Хлебзавод',
                        'Пл.Лицкевича',
                        'Исполком',
                        'Дк Малунцева',
                        'Кристалл',
                        'Ватутина',
                        'Поворотная',
                        'Дмитриева',
                        'Фестиваль',
                        'Логистический Комплекс',
                    ],
                    mapImage: require('@/assets/background/PervomaiskyPath.png'),
                },
                pakhomovka: {
                    title: 'Азово-Пахомовка - Лог. Комплекс',
                    stops: [
                        'Азово',
                        'Пахомовка',
                        'Техникум',
                        'Троицкое',
                        'Транзит',
                        'Дорстрой',
                        'Полынская',
                        'Ясная поляна',
                        'Логистический комплекс',
                    ],
                    mapImage: require('@/assets/background/PakhomovkaPath.png'),
                },
                airport: {
                    title: 'Аэропорт местных - Лог. Комплекс',
                    stops: [
                        'Аэропорт местных линий',
                        'Авиационная',
                        'Поликлиника',
                        'Диспетчерская',
                        'Сиб хлеб',
                        'Школа 55',
                        'Школа 53',
                        'Сады яблонька',
                        'Нефтебаза',
                        'Посёлок Самарка',
                        'Магазин',
                        'Логистический Комплекс',
                    ],
                    mapImage: require('@/assets/background/AirportPath.png'),
                },
                amurskaya: {
                    title: '21-я Амурская - Лог. Комплекс',
                    stops: [
                        '21-я Амурская',
                        '9-Линия',
                        'Жемчужина',
                        'Автосалоны',
                        'Нефтебаза',
                        'Логистический Комплекс',
                    ],
                    mapImage: require('@/assets/background/AmurskayaPath.png'),
                },
                automation: {
                    title: 'ПО Автоматика - Лог. Комплекс',
                    stops: [
                        'ПО Автоматика',
                        'Космос',
                        '25-линия',
                        '20-линия',
                        '16-линия',
                        'Театральная площадь',
                        'Жемчужина',
                        'Логистический Комплекс',
                    ],
                    mapImage: require('@/assets/background/AutomationPath.png'),
                },
                chkalovsky: {
                    title: 'Пос.Чкаловский - Лог. Комплекс',
                    stops: [
                        'ЖД вокзал',
                        'Площадь Серова',
                        'Омский государственный цирк',
                        'Дом печати',
                        'Жемчужина',
                        'Аэропорт местных линий',
                        'Авиационная улица',
                        'Поликлиника',
                        'улица Володарского',
                        'Школа № 55',
                        'Нефтебаза',
                        'Посёлок Самарка',
                        'Логистический Комплекс',
                    ],
                    mapImage: require('@/assets/background/ChkalovskyView.png'),
                },
                lenin: {
                    title: 'Пл.Ленина - Лог. Комплекс',
                    stops: [
                        'Пл. Ленина',
                        'Голубой огонёк',
                        'Диспетчерская',
                        'Логистический Комплекс',
                    ],
                    mapImage: require('@/assets/background/LeninView.svg'),
                },
                luzino: {
                    title: 'Пятилетка-Лузино - Лог. Комплекс',
                    stops: ['Пятилетка', 'Лузино', 'Входная', 'Логистический Комплекс'],
                    mapImage: require('@/assets/background/LuzinoPath.png'),
                },
                maluntseva: {
                    title: 'Малунцева - Лог. Комплекс',
                    stops: [
                        'Малунцева',
                        'Кристалл',
                        'мед. Акакадемия',
                        'Сибади',
                        'Арена',
                        'Лукашевича',
                        'Ватутина',
                        'Сады',
                        'Володарского',
                        'Сибхлеб',
                        'Самарка',
                        'Логистический Комплекс',
                    ],
                    mapImage: require('@/assets/background/MaluntsevaPath.png'),
                },
                railway: {
                    title: 'ЖД вокзал - Лог. Комплекс',
                    stops: [
                        'ЖД вокзал',
                        'Площадь Серова',
                        'Омский государственный цирк',
                        'Дом печати',
                        'Жемчужина',
                        'Аэропорт местных линий',
                        'Авиационная улица',
                        'Поликлиника',
                        'улица Володарского',
                        'Школа № 55',
                        'Нефтебаза',
                        'Посёлок Самарка',
                        'Логистический Комплекс',
                    ],
                    mapImage: require('@/assets/background/JD.svg'),
                },
            };

            return configs[route.name] || configs.pervomaisky;
        });

        const routeTitle = computed(() =>
            isThere.value
                ? routeConfig.value.title
                : routeConfig.value.title.split(' - ').reverse().join(' - '),
        );

        const currentStops = computed(() => routeConfig.value.stops);

        const currentMapImage = computed(() => routeConfig.value.mapImage);

        const scheduleForDay = computed(() => {
            if (!scheduleData.value) return [];
            const direction = isThere.value ? 'there' : 'back';
            const dayInEnglish = dayMapping[activeDay.value];
            const routeName = routeConfig.value.title.split(' - ')[0]; // Используем первую часть заголовка маршрута

            return (
                scheduleData.value[direction][routeName]?.schedule[dayInEnglish] || []
            );
        });

        const toggleDirection = () => {
            isThere.value = !isThere.value;
            localStorage.setItem('direction', isThere.value ? 'there' : 'back');
        };

        const setActiveDay = (day) => {
            toggleModal();
            activeDay.value = day;
        };

        const getCurrentDay = () => {
            const currentDayIndex = new Date().getDay();

            return days[currentDayIndex === 0 ? 6 : currentDayIndex - 1];
        };

        const loadDirectionFromStorage = () => {
            const savedDirection = localStorage.getItem('direction');

            if (savedDirection) {
                isThere.value = savedDirection === 'there';
            }
        };

        const loadScheduleData = async () => {
            try {
                const response = await fetch('/schedule.json');

                if (!response.ok) throw new Error('Ошибка при загрузке расписания');
                scheduleData.value = await response.json();
            } catch (error) {
                console.error('Ошибка:', error);
            }
        };

        const toggleModal = () => {
            box_list_button_schedule.value.classList.toggle('active');
            circle_button_list.value.classList.toggle('active');
            document.body.classList.toggle('no-scroll');
        };

        onMounted(() => {
            activeDay.value = getCurrentDay();
            loadScheduleData();
            loadDirectionFromStorage();
        });

        return {
            activeDay,
            isThere,
            days,
            routeTitle,
            scheduleForDay,
            currentStops,
            currentMapImage,
            toggleDirection,
            setActiveDay,
            toggleModal,
            box_list_button_schedule,
            circle_button_list,
        };
    },
};
</script>
