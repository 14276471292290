<template>
    <div class="box_stock">
        <img
            class="img_stock"
            :src="require(`@/assets/background/${image}`)"
            :alt="altText"
        />
        <p class="subtext_services_light color_orange text_align_right">
            {{ date }}
        </p>
    </div>
</template>

<script setup>
import { computed, defineProps } from 'vue';

const props = defineProps({
    image: {
        type: String,
        required: true,
    },
    date: {
        type: String,
        required: true,
    },
});

const altText = computed(() => `Акция на ${props.date}`);
</script>
