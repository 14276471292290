import { API_ENDPOINTS } from '@/utils/constants';
import { localStore, sessionStore } from './storage';

export const TOKEN_KEYS = {
    EMPLOYEE: {
        ACCESS: 'employee_access_token',
        REFRESH: 'employee_refresh_token',
        USER: 'employee_user',
        REMEMBER: 'employee_remember', // Добавлен ключ для remember
    },
    ADMIN: {
        ACCESS: 'admin_access_token',
        REFRESH: 'admin_refresh_token',
        USER: 'admin_user',
        REMEMBER: 'admin_remember', // Добавлен ключ для remember
    },
};

class BaseAuthService {
    constructor (tokenKeys, apiPath) {
        this.tokenKeys = tokenKeys;
        this.apiPath = apiPath;
    }

    saveAuth (authData, remember = null) {
        // Если remember не передан, берём значение из хранилища
        const shouldRemember = remember !== null ?
            remember :
            localStore.get(this.tokenKeys.REMEMBER) || false;

        // Сохраняем значение remember
        localStore.set(this.tokenKeys.REMEMBER, shouldRemember);

        const storage = shouldRemember ? localStore : sessionStore;
        const { accessToken, refreshToken, user } = authData;

        return storage.set(this.tokenKeys.ACCESS, accessToken) &&
               storage.set(this.tokenKeys.REFRESH, refreshToken) &&
               storage.set(this.tokenKeys.USER, user);
    }

    isRemembered () {
        return localStore.get(this.tokenKeys.REMEMBER) || false;
    }

    getAccessToken () {
        return this.isRemembered() ?
            localStore.get(this.tokenKeys.ACCESS) :
            sessionStore.get(this.tokenKeys.ACCESS);
    }

    getRefreshToken () {
        return this.isRemembered() ?
            localStore.get(this.tokenKeys.REFRESH) :
            sessionStore.get(this.tokenKeys.REFRESH);
    }

    getUser () {
        return this.isRemembered() ?
            localStore.get(this.tokenKeys.USER) :
            sessionStore.get(this.tokenKeys.USER);
    }

    isTokenExpiringSoon (token, thresholdMs = 30000) {
        if (!token) return true;

        try {
            const payload = JSON.parse(atob(token.split('.')[1]));
            const expiresIn = payload.exp * 1000 - Date.now();

            return expiresIn < thresholdMs;
        } catch {
            return true;
        }
    }

    async refreshTokens () {
        const refreshToken = this.getRefreshToken();

        if (!refreshToken) {
            throw new Error('No refresh token available');
        }

        try {
            const response = await fetch(`${this.apiPath}/refresh-token`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ refreshToken }),
            });

            if (!response.ok) {
                throw new Error('Failed to refresh token');
            }

            const data = await response.json();

            this.saveAuth({
                accessToken: data.tokens.accessToken,
                refreshToken: data.tokens.refreshToken,
                user: data.employee,
            }, this.isRemembered());

            return data.accessToken;
        } catch (error) {
            this.clearAuth();
            throw error;
        }
    }

    async checkAuth () {
        const accessToken = this.getAccessToken();

        if (!accessToken) {
            return false;
        }

        if (this.isTokenExpiringSoon(accessToken)) {
            try {
                await this.refreshTokens();

                return true;
            } catch {
                return false;
            }
        }

        return true;
    }

    clearAuth () {
        try {
            const isRemembered = this.isRemembered();
            const storage = isRemembered ? localStore : sessionStore;

            // Определяем тип токенов (EMPLOYEE или ADMIN)
            const isEmployee = this.tokenKeys === TOKEN_KEYS.EMPLOYEE;
            const tokenType = isEmployee ? 'EMPLOYEE' : 'ADMIN';

            // Получаем соответствующий набор токенов
            const tokensToRemove = TOKEN_KEYS[tokenType];

            // Очищаем все токены из соответствующего хранилища
            Object.entries(tokensToRemove).forEach(([key, value]) => {
                // Пропускаем очистку если значение не существует
                if (!storage.get(value)) return;

                storage.remove(value);

                // Проверяем успешность очистки
                if (storage.get(value)) {
                    console.warn(`Failed to remove ${key} token`);
                }
            });

            // Всегда очищаем флаг remember из localStorage
            if (localStore.get(this.tokenKeys.REMEMBER)) {
                localStore.remove(this.tokenKeys.REMEMBER);
            }

            return true;
        } catch (error) {
            console.error('Error clearing auth data:', error);

            return false;
        }
    }
}

export const EmployeeAuthService = new BaseAuthService(
    TOKEN_KEYS.EMPLOYEE,
    API_ENDPOINTS.EMPLOYEE,
);

export const AdminAuthService = new BaseAuthService(
    TOKEN_KEYS.ADMIN,
    API_ENDPOINTS.EMPLOYEE,
);
