<template>
    <section class="section_emploees">
        <div class="header_emploee">
            <p class="characteristics_title_meter">
                Акции конкурсы реклама
            </p>
            <div class="box_button_header_emploee">
                <div class="box_button_back_emploee">
                    <RouterLink
                        to="/employees"
                        class="button_standart"
                    >
                        Вернуться
                    </RouterLink>
                </div>
                <ModalHeader />
            </div>
        </div>
        <div class="section_stock">
            <StockItem
                v-for="(stock, index) in stocks"
                :key="index"
                :image="stock.image"
                :date="stock.date"
            />
        </div>
        <div class="flex_center">
            <div class="box_button_stock">
                <button
                    class="button_gray"
                    @click="loadMore"
                >
                    Показать еще
                </button>
            </div>
        </div>
    </section>
</template>

<script setup>
import { ref } from 'vue';
import ModalHeader from '@/components/layout/ModalHeader.vue';
import StockItem from '@/components/employees/StockItem.vue';

const stocks = ref([
    { image: 'stock_1.png', date: '27.09.2024' },
    { image: 'stock_2.png', date: '27.09.2024' },
    { image: 'stock_1.png', date: '27.09.2024' },
    { image: 'stock_2.png', date: '27.09.2024' },
    { image: 'stock_1.png', date: '27.09.2024' },
    { image: 'stock_2.png', date: '27.09.2024' },
]);

const loadMore = () => {
    // Implement load more functionality
    console.log('Load more stocks');
};
</script>
